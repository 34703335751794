/* eslint-disable */
const shopsData = [
  {
    id: 1,
    name: 'Brendle\'s',
    color: 'primary',
    avatar: 'B',
    products: 112,
    balance: '$13,575',
  },
  {
    id: 2,
    name: 'Tech Hifi',
    color: 'warning',
    avatar: 'T',
    products: 104,
    balance: '$11,145',
  },
  {
    id: 3,
    name: 'Lafayette',
    color: 'danger',
    avatar: 'L',
    products: 126,
    balance: '$12,356',
  },
  {
    id: 4,
    name: 'Packer',
    color: 'success',
    avatar: 'P',
    products: 102,
    balance: '$11,228',
  },
  {
    id: 5,
    name: 'Nedick\'s',
    color: 'info',
    avatar: 'N',
    products: 96,
    balance: '$9,235',
  },
  {
    id: 6,
    name: 'Hudson\'s',
    color: 'dark',
    avatar: 'H',
    products: 120,
    balance: '$14,794',
  },
  {
    id: 7,
    name: 'Tech Hifi',
    color: 'dark',
    avatar: 'T',
    products: 104,
    balance: '$11,145',
  },
  {
    id: 8,
    name: 'Brendle\'s',
    color: 'primary',
    avatar: 'B',
    products: 112,
    balance: '$13,575',
  },
  {
    id: 9,
    name: 'Lafayette',
    color: 'success',
    avatar: 'L',
    products: 126,
    balance: '$12,356',
  },
];

export {shopsData};
